@charset 'UTF-8';

// breakpoints
// -----------
// 単位を em に変換
@function convertEm( $width ) {
  @return '#{ $width / 16 }em';
}

// メディアクエリの書き出し
@mixin media($data) {
  $key: map-get( $breakpoints, $data );
  @media screen and ( min-width: convertEm($key) ) {
    @content;
  }
}


// grid
// ----
@mixin make-grid($width) {
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}

@function grid-margin($key, $i: null) {
  @if( $i != null ) {
    @return map-get($grid-gutter, $key) / 2 / $i / 10 + rem;
  } @else {
    @return map-get($grid-gutter, $key) / 2 / 10 + rem;
  }
}

// icon
// ----
// extend
@mixin icon-default {
  font-family: $icon-font-family;
  content: map-get( $icon-type, 'arrows' );
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
}

// 配列に単位をつける
// -------------
@function map-unit($value, $key, $unit: null) {
  @if( $unit == rem ) {
    @return map-get($value, $key) / 10 + $unit;
  } @else if( $unit == null ) {
    @return map-get($value, $key);
  } @else {
    @return map-get($value, $key) + $unit;
  }
}

// 二次元配列
// --------
@function multi-array( $array, $key, $prop ) {
  $index: map-get( $array, $key );
  @return map-get( $index, $prop );
}


// map-zip( $keys, $values )
// キーのリスト、値のリストの 2 つから新しい map を生成して返す
@function map-zip( $keys, $values ) {
  $length: length( $keys );
  $map: ();

  @for $i from 1 through $length {
    $tmp-keys: nth($keys, $i);
    $tmp-values: nth($values, $i);
    $tmp-map: ( $tmp-keys : $tmp-values );
    $map: map-merge($map, $tmp-map);
  }

  @return $map;
}


// fluid width
//
@mixin widthWrapper( $width, $padding ){
  @if ( $fluid-sm == false ) {
    min-width: map-unit( $width, sm ) + map-unit( $padding, sm ) * 2;
  }
  @if ( $fluid-md == false ) {
    @include media(md) {
      min-width: map-unit( $width, md ) + map-unit( $padding, md ) * 2;
    }
  }
  @if ( $fluid-lg == false ) {
    @include media(lg) {
      min-width: map-unit( $width, lg ) + map-unit( $padding, lg ) * 2;
    }
  }
}

@mixin widthContainer( $width ){
  @if ( $fluid-sm == true ) {
    width: 100%;
    max-width: map-unit( $width, sm );
  } @else {
    width: map-unit( $width, sm );
  }
  @if ( $fluid-md == true ) {
    @include media(md) {
      max-width: map-unit( $width, md );
    }
  } @else {
    @include media(md) {
      width: map-unit( $width, md );
    }
  }
  @if ( $fluid-lg == true ) {
    @include media(lg) {
      max-width: map-unit( $width, lg );
    }
  } @else {
    @include media(lg) {
      width: map-unit( $width, lg );
    }
  }
}
